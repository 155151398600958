import { PERMISSIONS } from './permissions';
import { getGroceryPermissions } from '../middleware';

const groceryMenuItems = (props) => {
  const { groceryPermissions, handleLogout, onHide } = props;
  const grocery = [
    {
      _id: '',
      label: 'Prodavnica',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.attributes}`,
      group: 'webshop',
      type: 'button',
      icon: '/assets/icons/products.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-store-dropdown',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts) && getGroceryPermissions(groceryPermissions, PERMISSIONS.readAttributes) && getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts),
      onClick: function(value) {},
      children: [
        {
          _id: '',
          label: 'Atributi',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.attributes}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-attributes',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readAttributes),
          onClick: () => {
            onHide(false);
          },
        },
        {
          _id: '',
          label: 'Artikli',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.products}`,
          group: 'webshop',
          type: 'anchor',
          icon: '/assets/icons/products.svg',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-products',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts),
          onClick: function() {
            onHide(false);
          },
          children: [],
        },
        {
          _id: '',
          label: 'Kategorije',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.categories}`,
          group: 'webshop',
          type: 'anchor',
          icon: '/assets/icons/categories.svg',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-categories',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readCategories),
          onClick: function() {
            onHide(false);
          },
          children: [],
        },
      ],
    },
    // {
    //   _id: '',
    //   label: 'Sadržaj',
    //   path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.attributes}`,
    //   group: 'webshop',
    //   type: 'button',
    //   icon: '/assets/icons/editmenu.svg',
    //   expanded: false,
    //   protected: false,
    //   dataCy: 'sidebar-grocery-store-dropdown',
    //   visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts) && getGroceryPermissions(groceryPermissions, PERMISSIONS.readAttributes) && getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts),
    //   onClick: function(value) {},
    //   children: [
    //     {
    //       _id: '',
    //       label: 'Baneri',
    //       path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.baners}`,
    //       group: 'users',
    //       type: 'anchor',
    //       icon: '/assets/icons/blog.svg',
    //       expanded: false,
    //       protected: false,
    //       dataCy: 'sidebar-grocery-roles',
    //       visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRoles),
    //       onClick: function() {
    //         onHide(false);
    //       },
    //       children: [],
    //     },
    //     {
    //       _id: '',
    //       label: 'Uslovi korišćenja',
    //       path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.terms}`,
    //       group: 'users',
    //       type: 'anchor',
    //       icon: '/assets/icons/blog.svg',
    //       expanded: false,
    //       protected: false,
    //       dataCy: 'sidebar-grocery-roles',
    //       visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRoles),
    //       onClick: function() {
    //         onHide(false);
    //       },
    //       children: [],
    //     },
    //     {
    //       _id: '',
    //       label: 'P. Privatnosti',
    //       path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.privacy}`,
    //       group: 'users',
    //       type: 'anchor',
    //       icon: '/assets/icons/blog.svg',
    //       expanded: false,
    //       protected: false,
    //       dataCy: 'sidebar-grocery-roles',
    //       visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRoles),
    //       onClick: function() {
    //         onHide(false);
    //       },
    //       children: [],
    //     },
    //     {
    //       _id: '',
    //       label: 'Dostava',
    //       path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.delivery}`,
    //       group: 'users',
    //       type: 'anchor',
    //       icon: '/assets/icons/blog.svg',
    //       expanded: false,
    //       protected: false,
    //       dataCy: 'sidebar-grocery-roles',
    //       visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRoles),
    //       onClick: function() {
    //         onHide(false);
    //       },
    //       children: [],
    //     },
    //     {
    //       _id: '',
    //       label: 'Kolačići',
    //       path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.cookies}`,
    //       group: 'users',
    //       type: 'anchor',
    //       icon: '/assets/icons/blog.svg',
    //       expanded: false,
    //       protected: false,
    //       dataCy: 'sidebar-grocery-roles',
    //       visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRoles),
    //       onClick: function() {
    //         onHide(false);
    //       },
    //       children: [],
    //     },
    //     {
    //       _id: '',
    //       label: 'O nama',
    //       path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.aboutUs}`,
    //       group: 'users',
    //       type: 'anchor',
    //       icon: '/assets/icons/blog.svg',
    //       expanded: false,
    //       protected: false,
    //       dataCy: 'sidebar-grocery-roles',
    //       visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRoles),
    //       onClick: function() {
    //         onHide(false);
    //       },
    //       children: [],
    //     },
    //     {
    //       _id: '',
    //       label: 'Blog',
    //       path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.blogs}`,
    //       group: 'users',
    //       type: 'anchor',
    //       icon: '/assets/icons/blog.svg',
    //       expanded: false,
    //       protected: false,
    //       dataCy: 'sidebar-grocery-roles',
    //       visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRoles),
    //       onClick: function() {
    //         onHide(false);
    //       },
    //       children: [],
    //     },
    //     {
    //       _id: '',
    //       label: 'Napiši blog',
    //       path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.createBlog}`,
    //       group: 'users',
    //       type: 'anchor',
    //       icon: '/assets/icons/blog.svg',
    //       expanded: false,
    //       protected: false,
    //       dataCy: 'sidebar-grocery-roles',
    //       visible: false,
    //       onClick: function() {
    //         onHide(false);
    //       },
    //       children: [],
    //     },
    //   ],
    // },
    {
      _id: '',
      label: 'Newsletter',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.newsletter}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/activity.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-roles',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRoles),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Zahtevi za izlog',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.shopwindowrequests}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/blog.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-roles',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRoles),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Porudžbine',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.orders}`,
      group: 'webshop',
      type: 'anchor',
      icon: '/assets/icons/orders.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-orders',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readOrders),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Galerija',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.gallery}`,
      group: 'webshop',
      type: 'anchor',
      icon: '/assets/icons/gallery.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-gallery',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readOrders),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Uloge',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.roles}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/roles.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-roles',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRoles),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Korisnici',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.customers}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/customers.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-customers',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readCustomers),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Osoblje',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.employees}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/employees.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-employees',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readEmployees),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Pretrage',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.searchPage}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/save_light.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-search-page',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readEmployees),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Logovi',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.activity}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/activity.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-activity-log',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readActivity),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Nalog',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.profile}`,
      group: 'account',
      type: 'anchor',
      icon: '/assets/icons/profile.svg',
      expanded: true,
      protected: false,
      dataCy: 'sidebar-grocery-profile',
      visible: true,
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Odjavi se',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.login}`,
      group: 'account',
      type: 'button',
      icon: '/assets/icons/logout.svg',
      expanded: true,
      protected: false,
      dataCy: 'sidebar-grocery-logout',
      visible: true,
      onClick: () => {
        handleLogout();
      },
      children: [],
    },
  ];

  return grocery;
};

export { groceryMenuItems };
