import React, { useState, useEffect } from 'react';
import { SERVER_URL } from '../../../config/index';
import { useAuth } from '../../../context/useAuth';
import Axios from 'axios';
import Message from '../../../components/message/Message';

const Newsletter = () => {
  const { user } = useAuth();
  const { token } = user;
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let res;
      try {
        res = await Axios.get(`${SERVER_URL}/get-subscribers`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        });
      } catch (error) {
        console.log(error);
      }

      setData(res?.data);
    };

    fetchData();
  }, []);

  return (
    <div>
      <table className="table">
        <thead className="thead">
          <tr>
            <th style={{ width: '50%' }}>Ime</th>
            <th style={{ width: '50%' }}>Email</th>
          </tr>
        </thead>
        <tbody className="tbody">
          {data && data?.length > 0 ? (
            data?.map((item) => {
              return (
                <tr key={item._id}>
                  <td>
                    <div className="d-flex gap-x-2 align-center">
                      <span>
                        {item?.firstName} {item?.lastName} {item?.companyName}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex gap-x-2 align-center">
                      <div style={{ display: 'flex' }}>
                        <span>{item?.email}</span>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td
                colSpan={20}
                style={{
                  backgroundColor: '#fff',
                  height: '200px',
                  border: '0',
                }}
              >
                <Message className="content-message">
                  <h2 style={{ color: '#666' }}>Data not found.</h2>
                </Message>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Newsletter;
